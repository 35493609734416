<template>
  <div class="container">
    <div class="row center-xs">
      <div class="col-sm-12">
        <h2 class="mb5 step-title">
          {{ $t('Product review') }}
        </h2>
      </div>
      <div class="col-sm-12">
        <h2 class="mt0 mb10 step-subtitle">
          {{ $t('Summary') }}
        </h2>
      </div>
      <div class="col-xs-8">
        <p class="step-description">
          {{ $t("Your product has been sent to us and within 48 hours you will receive an email notification from us with information about the first stage of verification.") }}
        </p>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-8">
        <h1 class="bold-heading">
          {{ isMoreThanOneProduct ? $t("Your products") : $t("Your product") }}
        </h1>
      </div>
    </div>
    <div class="row center-xs">
      <div class="flex wrap col-xs-10 col-sm-8 col-md-10 col-lg-8 col-xl-6" :class="{'center-xs': products.length === 1}">
        <product-block v-for="product in products" :product="product" :key="product.key" />
      </div>
    </div>
    <div class="row center-xs mb20">
      <div class="row center-xs col-xs-10 col-sm-8 col-md-10 col-lg-8 col-xl-6">
        <div class="p0 col-xs-12 col-md-6" :class="{ 'col-md-12': products.length > 1 }">
          <div class="row mx10 pl15 center-xs products-summary">
            <p class="col-xs-4 my5 mt25 align-left paragraph">
              {{ `${$t("Total")}: ` }}
            </p>
            <p class="col-xs-8 my5 mt25 align-left weight-700 pl10 bold-values">
              {{ getTotalProductsPrice | price(storeView) }}
            </p>
            <p class="col-xs-4 my5 align-left paragraph">
              {{ `${$t("You earn")}: ` }}
            </p>
            <p class="col-xs-8 my5 align-left weight-700 pl10 bold-values">
              {{ getTotalProductsProfit | price(storeView) }}
            </p>
            <p class="col-xs-4 mt5 mb25 align-left paragraph">
              {{ `${$t("Delivery")}: ` }}
            </p>
            <p class="col-xs-8 my5 align-left weight-700 pl10 bold-values">
              {{ getCurrentShippingName(langCode) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-8 col-md-4 p0 mb20">
        <button-full class="w-100 button-full" @click.native="pushProductsToMarketplace">
          {{ $t('Offer for sale') }}
        </button-full>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import ProductBlock from 'theme/components/core/blocks/Selling/ProductBlock'
import { mapGetters } from 'vuex'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'ProductVerificationStep',
  components: {
    ButtonFull,
    ProductBlock
  },
  computed: {
    ...mapGetters({
      getTotalProductsPrice: 'selling/getTotalProductsPrice',
      getTotalProductsProfit: 'selling/getTotalProductsProfit',
      getCurrentShippingName: 'selling/getCurrentShippingName'
    }),
    products () {
      return this.$store.state.selling.products
    },
    isMoreThanOneProduct () {
      return this.$store.state.selling.products.length > 1
    },
    storeView () {
      return currentStoreView()
    },
    langCode () {
      return this.storeView.i18n.defaultLocale.replace('-', '_')
    }
  },
  methods: {
    async pushProductsToMarketplace () {
      await this.$store.dispatch('selling/createSeveralProducts', this.langCode)
      this.$router.push(localizedRoute('/thank-you-for-selling-page'))
      this.$store.dispatch('selling/afterConfirmSection')
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-title {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1.94px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .step-subtitle {
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.32px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .step-description {
    font-size: 12px;
    padding: 0 22%;
    @media (max-width: 992px) {
    padding: 0 7%;
    }
    @media (max-width: 768px) {
    padding: 0 5%;
    }
  }
  .bold-heading {
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.41px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .products-summary {
    border-bottom: 1px solid #bfbfbf;
    @media (max-width: 768px) {
      max-width: unset;
      min-width: unset;
      border-top: 1px solid #bfbfbf;
    }
  }
  .paragraph {
    font-size: 12px;
    white-space: nowrap;
    &.first{
      margin-top: -4px;
    }
  }
  .bold-values {
    font-weight: 700;
    font-size: 12px
  }
  .button-full {
    @media (max-width: 768px) {
      font-size: 12px
    }
  }
</style>
